/** @jsx jsx */

import { Styled, jsx, Flex, Box, Grid, Card } from "theme-ui"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import Img from 'gatsby-image'

import SEO from "../components/seo"

import BasicHero from '../components/BasicHero'
import BgImage from '../components/BgImage'
import Button from '../components/button'

import WhiteLogo from '../images/icons/nexprotek-white-background-icon.svg'
import GrayFlame from '../images/icons/half-fire-icon.svg'

/* Icon Group*/
import ResistIcon from '../images/icons/resist-icon-white.svg'
import FireIcon from '../images/icons/fire-icon-white.svg'
import ToxicIcon from '../images/icons/not-toxic-icon-white.svg'
import MeltingIcon from '../images/icons/non-melting-icon-white.svg'
import FoldedIcon from '../images/icons/foldable-icon-white.svg'
import SizeIcon from '../images/icons/size-icon-white.svg'

import { Container } from '../components/item'



export const query = graphql`
  query {
    posts: allWordpressPost(
      filter: {categories: {elemMatch: {slug: { eq: "nexprotek" }}}} 
      limit: 3,
    ){
      edges {
        node {
          slug
          title
          excerpt
          date(formatString: "MMMM DD, YYYY")
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    hero: file(relativePath: { eq: "metal-welding-closeup.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    interior: file(relativePath: { eq: "about-background-fade.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    welding: file(relativePath: { eq: "man-welding.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    industrial: file(relativePath: { eq: "industrial-background.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const AboutPage = ({data}) => (

  <Layout>
    <SEO 
      title="About Nexprotek" 
      // description=""
    />
    
    <BasicHero 
      fluid={data.hero.childImageSharp.fluid}  
      title={"Man Welding Hero Image"} 
      heroTitle={"About Nexprotek"}
      adjacentSectionColor={'darkGray'}
    />
    
    <Box as="section" color={'white'} sx={{
      position: 'relative',
      backgroundColor: 'darkGray',
    }}>
      <Container py={13} sx={{position: 'relative', zIndex: 11}}>
        <Box sx={{textAlign: 'center', maxWidth: '815px', margin: '0 auto'}}>
          <Styled.h2 sx={{color: 'yellow'}}>Safety First</Styled.h2>
          <Styled.p sx={{variant: 'text.content'}}>Nexprotek provides thermal protection
          when and where you need it most. In your home or workplace, Nexprotek is there for your safety, security and peace of mind.</Styled.p>
        </Box>
      </Container>

      <BgImage 
        fluid={data.interior.childImageSharp.fluid} 
        title="Interior Shot" 
        height={'auto'} 
        overlayGradient={'linear-gradient(180deg, #58595B 34.9%, rgba(88, 89, 91, 0) 100%), url(../images/about-background-interior-shot.png)'}
        sx={{
          marginTop: '-150px',
        }}
      >
        <Box>
          <Container>
            <Grid columns={[1, 2]} p={[4,12]} pt={'150px'} sx={{justifyItems: 'center', alignItems: 'space-between', maxWidth: '1000px', margin: '0 auto', gridColumnGap: '180px', position: 'relative', zIndex: 100 }}>
              <Box sx={{ width: '340px', textAlign: ['center', 'left']}}>
                <Styled.h3 sx={{letterSpacing: '0.1em'}}>Vigilant, Safe, Secure</Styled.h3>
                <Styled.p sx={{variant: 'text.body'}}>we are always looking out for you, concerned for your safety and security</Styled.p>
              </Box>
              <Box sx={{ width: '340px', textAlign: ['center', 'left']}}>
                <Styled.h3 sx={{letterSpacing: '0.1em'}}>Dependable, Prepared, Protective</Styled.h3>
                <Styled.p sx={{variant: 'text.body'}}>you can count on us to help you be prepared and protected</Styled.p>
              </Box>
              <Box sx={{ width: '340px', textAlign: ['center', 'left']}}>
                <Styled.h3 sx={{letterSpacing: '0.1em'}}>Smart, Sensible, Proactive</Styled.h3>
                <Styled.p sx={{variant: 'text.body'}}>proactive protection - smart, sensible precautions have been taken to ensure safety</Styled.p>
              </Box>
              <Box sx={{ width: '340px', textAlign: ['center', 'left']}}>
                <Styled.h3 sx={{letterSpacing: '0.1em'}}>Professional, Trustworthy, Caring</Styled.h3>
                <Styled.p sx={{variant: 'text.body'}}>we are always looking out for you, concerned for your safety and security</Styled.p>
              </Box>
            </Grid>
          </Container> 
        </Box>
          <div sx={{ 
            height: '100%', 
            width: 'auto', 
            position: 'absolute', 
            top: 0,
            right: 0,
            zIndex: 2,

            '@media screen and (max-width:768px)': {
              top: 'unset',
              bottom: '-35%',
            }
          }}>
              <GrayFlame />
          </div>
      </BgImage>
      
    </Box>

    <Box as="section">  
      <Container py={[8,13]}>
        <Box my={8}>
          <Styled.h3>Our Products</Styled.h3>
          <Styled.p>Our durable, state-of-the art products implement the best available technology. We’re committed to vertical integration in the manufacturing process, and we keep a close eye on our products from production to delivery. Our hot work fabrics exceed the highest international standards for heat resistance, health, and environmental safety.</Styled.p>
        </Box>
        <Flex sx={{flexDirection: ['column-reverse', 'row']}}>
          <Box pr={[0,12]} sx={{flex: ['1 1 100%','2 1 65%']}}>
            <Styled.h3 sx={{marginTop: [8, 0] }}>Diversity in Application</Styled.h3>
            <Styled.p>Fire blankets can be used in more contexts than most people think. Our diverse line of high-temperature textiles includes finished products and custom materials for construction, automotive, and marine industries, building and vehicle insulation, welding, and household fire prevention. Whether you’re an industry supplier, a DIYer working on a home project, a family or business in need of protective equipment, or a welder looking for the toughest textiles available, look to us.</Styled.p>
            <hr sx={{ borderTop: '3px solid #bbb', width: '100%', marginTop: '50px' }} />
            <Box py={4} sx={{textAlign: 'center'}}>
              <Styled.h2 sx={{fontWeight: 'bold', fontSize: ['32px', '48px']}}>Your first line of defense.</Styled.h2>
            </Box>
            <hr sx={{ borderTop: '3px solid #bbb', width: '100%', marginBottom: '50px' }} />
            <Styled.h3>Testing and manufacturing</Styled.h3>
            <Styled.p> We hold ourselves to the highest standards. Our goal is to provide a safety product that protects you where and when you need it most, and there isn't room for error. We rigorously monitor for quality at every step in production.</Styled.p>
          </Box>
          <Img sx={{flex: ['1 1 100%', '1 1 35%']}} fluid={data.welding.childImageSharp.fluid} />
        </Flex>
      </Container>
    </Box> 

    <Box as="section" sx={{ 
      color: 'white',
      background: 'linear-gradient(174.8deg, #FDCB10 7.3%, #EF3E23 84.56%)',
      position: 'relative',
      zIndex: 1,
      }}>
      <Container>
        
        {/* <Image sx={{ height: '100%', width: 'auto', position: 'absolute', top: 0, left: 0, zIndex: '1' }} src={backgroundLogo} /> */}

        <Flex py={14} sx={{
          width: '100%',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          position: 'relative',
          zIndex: '10',
        }}>
          <Styled.h2>We've got you covered.</Styled.h2>
          <p sx={{ variant: 'text.content', maxWidth: 820 }}>At Nexprotek, trial-by-fire is all in a day’s work. At home or at work in a high-heat industry, approach flames and extreme temperatures with performance-tested tools you can trust.</p>
        </Flex>
      </Container>

        <div sx={{ 
          height: '100%', 
          width: 'auto', 
          position: 'absolute', 
          top: '10px', 
          left: '-60px', 
          zIndex: 10 }}>
            <WhiteLogo />
        </div>

    </Box>
    
    <BgImage
       fluid={data.industrial.childImageSharp.fluid}
       title="industrial background image"
       height="auto"   
    >
      <Container py={13} color={'white'} sx={{textAlign: 'center'}}>
        <Styled.h3 sx={{variant: 'text.caps' }}>Latest News</Styled.h3>
        <Flex my={10} sx={{textAlign: 'initial', flexDirection: ['column', 'row'], justifyContent: 'center'}}>
          { data.posts.edges.map((data, index) => (
            <Card key={index} mx={7} my={[4,0]} sx={{
              backgroundColor: 'white',
              color: 'black',
              flex: '1 1 450px',
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '450px',
              }}>
              <Box sx={{height: '250px'}}>
                {console.log(data)}
                <Img fluid={data.node.featured_media.localFile.childImageSharp.fluid } style={{ height: '100%' }}/>
              </Box>
              <Flex p={5} sx={{flex: '1', flexDirection: 'column', justifyContent: 'space-between'}}>
                <Styled.h3 sx={{ marginBottom: 0, fontWeight: 'medium'}}>{ data.node.title }</Styled.h3>
                <div sx={{ variant: 'text.body'}} dangerouslySetInnerHTML={{__html: data.node.excerpt}} />
                <p sx={{fontSize: 0, color: 'darkGray'}}>{ data.node.date }</p>
                <Button to={`/blog/${data.node.slug}`} text='Read More' sx={{marginTop: '40px', marginX: 'auto'}} />
              </Flex>
            </Card>
          ))}
        </Flex>
      </Container>

      <Container py={13} sx={{
        backgroundColor: 'black',
        color: 'white',
        textAlign: 'center',
      }}>
        <Styled.h1 as="span" sx={{
          textAlign: 'center',
          fontWeight: 'bold'
        }}>Prepare. Prevent. Protect.</Styled.h1>
        <Grid columns={[2, 3, 6]} mt={'60px'}>
          <Flex sx={{flexDirection: 'column', alignItems: 'center', flex: '1'}}>
            <ResistIcon width={'125px'} />
            <p sx={{ fontSize: 1, fontWeight: 'bold'}}>Resists instantanious burning of 2000°F</p>
          </Flex>
          <Flex sx={{flexDirection: 'column', alignItems: 'center', flex: '1'}}>
            <FireIcon width={'125px'} />
            <p sx={{ fontSize: 1, fontWeight: 'bold'}}>Withstands long term heat of 600-850°F</p>
          </Flex>
          <Flex sx={{flexDirection: 'column', alignItems: 'center', flex: '1'}}>
            <ToxicIcon width={'125px'} />
            <p sx={{ fontSize: 1, fontWeight: 'bold'}}>Does not produce toxic waste gases</p>
          </Flex>
          <Flex sx={{flexDirection: 'column', alignItems: 'center', flex: '1'}}>
            <MeltingIcon width={'125px'} />
            <p sx={{ fontSize: 1, fontWeight: 'bold'}}>No burning or melting characteristics</p>
          </Flex>
          <Flex sx={{flexDirection: 'column', alignItems: 'center', flex: '1'}}>
            <FoldedIcon width={'125px'} />
            <p sx={{ fontSize: 1, fontWeight: 'bold'}}>Light weight, portable, flexible and foldable</p>
          </Flex>
          <Flex sx={{flexDirection: 'column', alignItems: 'center', flex: '1'}}>
            <SizeIcon width={'125px'} />
            <p sx={{ fontSize: 1, fontWeight: 'bold'}}>Available in varying thickness and sizes</p>
          </Flex>
        </Grid>
      </Container>
    </BgImage>
    
  
  </Layout>
)

export default AboutPage
